@import "../../main.scss";

.bgImage {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: -2;
  background-image: url("../../Resources/Promo/gradient.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.main {
  background-image: url("../../Resources/Promo/bg.png");
  background-size: 100%;
  background-repeat: no-repeat;
  margin-bottom: -90px;
  padding-bottom: 90px;
  min-height: 945px;
}

.main .content {
  h1 {
    font-size: 28px;
    font-weight: 600;
  }

  h2 {
    font-size: 18px;
    font-weight: 500;
    margin-top: 25px;

    strong {
      font-size: 28px;
      font-weight: 700;
    }
  }

  h3 {
    font-size: 14px;
    font-weight: 500;
    margin-top: 25px;

    strong {
      font-weight: 700;
    }
  }

  display: grid;
  grid-template-columns: 25% 50% 25%;
  .center {
    text-align: center;
    .underTitle {
      margin-bottom: 3rem;
    }
  }
}

@media only screen and (max-width: $WIDTH_MD) {
  .main {
    background-image: none;
    .content {
      grid-template-columns: 1fr;
      .left,
      .right {
        display: none;
      }
    }
  }
}

.progressBar {
  width: 80%;
  margin: -2rem auto 0;
  h3 {
    margin-bottom: -1rem;
  }
  .progressBarText {
    font-weight: 700;
    position: relative;
    z-index: 111;
    top: 39px;
  }
  .percent {
    width: 30px;
    display: inline-block;
  }
  .value, .total {
    min-width: 50px;
    display: inline-block;
  }
  .value {
    text-align: right;
  }
  .total {
    text-align: left;
  }
}

.yourReferralsTable {
  width: 100%;
  border-spacing: 0;
  color: white;
  font-size: 0.88rem;
  td,
  th {
    padding: 11px 22px;
    background-color: #a258ff;
    text-align: left;
  }
  @media only screen and (max-width: $WIDTH_SM) {
    td,
    th {
      padding: 11px;
    }
  }
  td:last-child,
  th:last-child {
    text-align: right;
  }
  td {
    border-top: solid 1px white;
  }
}

div.popperReferrals {
  margin-bottom: -20px !important;
}

.popperProgress {
  cursor: pointer;
}

.yourReferralsTablePopper {
  width: 100%;
  border-spacing: 0;
  color: black;
  font-size: 0.88rem;
  border: solid 1px #588dff;
  td,
  th {
    padding: 11px 22px;
    background-color: #dee8ff;
    text-align: left;
  }
  @media only screen and (max-width: $WIDTH_SM) {
    td,
    th {
      padding: 11px;
    }
  }
  td:last-child,
  th:last-child {
    text-align: right;
  }
  td {
    border-top: solid 1px #588dff;
  }
}

.circularProgress48centre {
  position: absolute;
  top: calc(50vh - 24px);
  left: calc(50vw - 24px);
}

.footerHaveCoin {
  position: relative;
  .imageRight {
    position: absolute;
    bottom: 15px;
    right: -90px;
    z-index: -1;
    @media only screen and (max-width: 923px) {
      display: none;
    }
  }
}

.main .content div.borderBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  border: 2px solid #884af3;
  border-radius: 4px;
  padding: 24px;
  margin: 24px auto;

  @media (min-width: 923px) {
    width: 70%;
  }

  h1 {
    font-weight: 400;
  }
  p {
    margin: 4px 0;
  }

  &.haveCoin {
    position: relative;
    h1 {
      color: #fff;
      background: linear-gradient(89.78deg, #0398e6 -0.32%, #e740b4 100.53%);
      padding: 0 30px;
    }
    .bgImageBorderBox {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}
