.cardMedia {
  cursor: pointer;
  // border: solid 1px #A7A7A7;
  &.error {
    border: solid 2px #d32f2f;;
  }
}
video.cardMedia {
  height: auto;
}
img.cardMedia {
  width: 190px;
}

img.cardMedia.maxWidth {
  width: 100%;
}

audio {
  width: 100%;
}

.circularProgress {
  // border: solid 1px #A7A7A7;
  display: flex;
  justify-content: center;
  align-items: center;
}
