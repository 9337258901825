.btnMenu {
  display: flex;
  height: 100%;
  align-items: center;
  margin-right: 14px;
  svg {
    cursor: pointer;
  }
  
}
.main {
  max-width: 100%;
  left: auto !important;
  right: 0;
}
