.react-fancybox .thumbnail img {
  max-width: 100%;
  max-height: 340px;
  width: auto;
  cursor: pointer;
}
.react-fancybox .thumbnail {
  text-align: center;
}

.react-fancybox .image-box {
  max-width: calc(100% - 34px);
}

.react-fancybox .image-box img {
  max-width: 85vw;
}

.react-fancybox .image-box img:not(.close-btn) {
  width: auto;
  height: auto;
}

.react-fancybox .image-box img.close-btn:hover {
  cursor: pointer;
}

@media only screen and (max-width: 960px) {
  .react-fancybox .thumbnail img {
    max-height: none;
  }
}
