.pending {
  display: flex;
  background-color: red;
  border-color: red;
  align-items: center;
  margin-right: 8px;
  p {
    border: 1px solid red !important;
    margin: 0;
  }
  .loaderSmall {
    margin: 0  5px 0 0;
    display: flex;
    align-items: center;
  }
}