@import '../../main.scss';

.main {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  & > div:nth-child(1) {
    width: 251px;
  }
  & > div:nth-child(2) {
    width: 100%;
    max-width: 500px;
  }
}

.right {
  display: grid;
  gap: 1rem;
  grid-template-columns: 180px 1fr;
  .description {
    grid-column: 1/3;
  }
  .flexStartRow {
    grid-column: 1/3;
  }
  .flexStartRow, .flexStart {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

.arrowLeft {
  margin-right: 5px;
}

@media only screen and (max-width: $WIDTH_SM) {
  .main {
    & > div:nth-child(2) {
      max-width: 100%;
    }
    .right {
      grid-template-columns: 1fr 1fr;
    }
  }
  .boxImage {
    margin-right: auto;
    margin-left: auto;
  }
  .boxBtn {
    text-align: center;
    grid-column: 1/3;
  }
}

