@import '../../main.scss';

.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 343px);
  gap: 1.5rem;
}

.topMenu {
  a {
    text-decoration: none;
    color: #000;
  }
  .active {
    text-decoration: underline;
  }
  display: flex;
  column-gap: 1rem;
  margin-bottom: 1rem;
}

.disableItemMenu {
  pointer-events: none;
  user-select: none;
  opacity: .7;
}


@media only screen and (max-width: $WIDTH_SM) {
  .main {
    h1 {
      text-align: center;
    }
    text-align: center;
  }
  .list {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}
