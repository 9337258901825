@import '../../main.scss';

.main {
  color: #ffffff;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  gap: 0.5rem;
  padding-right: 8px;
  padding-left: 8px;
  p {
    line-height: 1rem;
    font-size: 0.5rem;
    border: 1px solid #fff;
    vertical-align: middle;
    padding: 0 0.5rem;
    white-space: nowrap;
    &.alert {
      color: red;
      border-color: red;
    }
  }
}
