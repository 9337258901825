@import '../../main.scss';

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

@media only screen and (max-width: $WIDTH_SM) {
  .main {
    h1 {
      text-align: left;
    }
    text-align: center;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
