@import "../../main.scss";

body {
  font-family: "Rawline", "Raleway", "Montserrat", "Roboto", Verdana, Arial, Helvetica, sans-serif;
}

.col.left,
col.right {
  @media (min-width: 923px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    overflow: visible;
  }
}

.illustration {
  display: inline-block;
  width: 100%;
  height: auto;

  &.hand {
    margin-top: 20px;
  }
  &.cat {
    margin-top: 100px;
  }
}
.col.center {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (min-width: 923px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 0 0 600px;
  }
}

.text-gradient {
  background: linear-gradient(90deg, #0398e6 5%, #e740b4 95%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  &.huge {
    font-size: 96px;
    font-weight: 700;
    margin-top: -42px !important;
    margin-bottom: -17px !important;
  }
}

.progress-100 {
  position: relative;
  width: 70%;
  height: 36px;
  margin: 10px;
  box-shadow: 0 0 5px #ccc;
  background-color: white;
  z-index: 20;

  .progress-x {
    position: relative;
    height: 36px;
    transition-property: width;
    transition: 200ms;
    background-image: linear-gradient(90deg, #69edff 0%, #f650b4 60%, #c361e5 95%);
    z-index: 30;
    &.green {
      background: linear-gradient(90deg, #4DD04B 0%, #54DD48 0.01%, #C8F9B6 100%);
    }
  }
}

.progress-100.progressV2 {
  width: 100%;
  margin: 10px 0;
}

.nosell {
  display: flex;
  align-items: center;
  margin: 2rem 0;

  .text {
    color: #f052ba;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: left;
  }
  .icon {
    display: inline-flex;
    flex: 0 0 4rem;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    padding: 0.5rem;
    color: #f052ba;
    border-radius: 2rem;
    border: 3px solid #f052ba;
    font-size: 2rem;
    font-weight: 700;
    margin-right: 1rem;
  }
}

.promo__footer {
  max-width: 1024px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  justify-content: center;
  margin: 3rem auto;
  @media (max-width: 923px) {
    grid-template-columns: 1fr;
  }

  .card {
    display: flex;
    min-height: 120px;
    border-radius: 0.5rem;
    padding: 1rem;
    color: white;

    img {
      height: 120px;
    }

    main {
      flex: 1 1 60%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    aside {
      flex: 0 0 30%;
    }

    &.one {
      background-color: #77b6ff;
    }
    &.two {
      background-color: #ffc977;
    }
    &.three {
      background-color: #b67dff;
    }

    &.oneHaveCoin {
      background-color: #0fbf7f;
    }
    &.twoHaveCoin {
      background-color: #f2b54a;
    }
    &.threeHaveCoin {
      background-color: #2bc9ec;
    }

    h3 {
      font-size: 20px;
      font-weight: 400;
    }
    p {
      font-size: 1rem;
      font-weight: 300;
    }
    .card-btn {
      width: 145px;
      cursor: pointer;
      padding: 10px !important;
      background: linear-gradient(90deg, #ff9de4 4.22%, #fe57b4 96.1%);
      box-shadow: 0px 4px 5px rgba(126, 125, 125, 0.25);
      border-radius: 4px;
      color: white;
      text-transform: unset;
      text-decoration: none;
      height: 40px;
      display: inline-block;
      text-align: center;
      line-height: 1.1rem !important;
    }
    button.card-btn[disabled] {
      cursor: auto;
      background: grey;
    }
  }
}

.pm-button, a.pm-button{
  cursor: pointer;
  background-image: linear-gradient(180deg, #3dd3ff 0%, rgba(216, 61, 255, 0.71) 100%);
  border: 0;
  padding: 15px 30px;
  font-size: 14px;
  color: white;
  border-radius: 4px;
}
button.pm-button[disabled] {
  cursor: auto;
  opacity: 0.2;
}

.cat-an {
  animation: jump 10s ease-in-out 2s infinite forwards;
}

.hand-an {
  animation: wave 1s ease-in-out 0.5s infinite alternate forwards;
}

@keyframes jump {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes wave {
  0% {
    transform: rotate(-25deg);
  }
  100% {
    transform: rotate(-5deg);
  }
}
