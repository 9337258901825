@import '../../main.scss';

.main {
  background-color: #fff;
  width: 343px;
  .flexsBetween {
    display: flex;
    justify-content: space-between;
  }
  .flexStart {
    display: flex;
    justify-content: flex-start;
  }
  .flexCenter {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: $WIDTH_SM) {
  .main {
    width: 100%;
    max-width: 343px;
  }
}