.main {
  background-color: #fff;
  width: 161px;
  .flexsBetween {
    display: flex;
    justify-content: space-between;
  }
  .flexStart {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
  }
  .flexCenter {
    display: flex;
    justify-content: center;
  }
  .contentCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 175px);
  }
  .name {
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .imgIcon {
    cursor: pointer;
  }
  .mediaLink {
    display: flex;
    height: 161px;
    align-items: center;
    justify-content: center;
    img, video {
      width: 100%;
    }
  }
}
