@import '../../main.scss';

.mainForm {
  display: flex;
  gap: 2rem;
  & > div:nth-child(1) {
    width: 100%;
    max-width: 500px;
  }
  & > div:nth-child(2) {
    width: 100%;
    max-width: 400px;
  }
  .inputField {
    width: 100%;
    & > div {
      input {
        padding-top: 8px;
        padding-bottom: 12px;
      }
    }
    &.multiLine > div {
      padding-top: 8px;
    }
    &.selectField > div > div {
      padding-top: 9px;
      padding-bottom: 12px;
    }
  }
  textarea {
    padding: 0;
  }
}
.uploadInput {
  display: none
}

.flexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

a.downloadBtnBox {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #41C7C0;
  width: 43px;
  height: 43px;
  margin-bottom: 24px;
  img {
    height: 23px;
    width: auto;
  }
  &.userLicense {
    margin-bottom: 0;
  }
}

a.downloadBtnBox.disabled {
  background-color: #A7A7A7;
}

.rightBox {
  display: grid;
  grid-template-columns: 1fr 43px;
  column-gap: 15px;
  align-items: end;
  .left {
    grid-column: 1 / 2;
  }
}

.boxSubmitBtn {
  button {
    font-weight: 700;
  }
}

.userLicense {
  display: flex;
  justify-content: space-between;
  p {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  img:hover {
    cursor: pointer;
  }
}

@media only screen and (max-width: $WIDTH_SM) {
  .mainForm {
    flex-wrap: wrap;
    & > div:nth-child(1), & > div:nth-child(2) {
      max-width: 100%;
    }
  }
  .boxImage {
    margin-right: auto;
    margin-left: auto;
  }
  .boxSubmitBtn {
    text-align: center;
  }
  .boxVerification {
    .flexStart {
      justify-content: center;
    }
  }
}
