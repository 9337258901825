@import "../../main.scss";

$height: 86px;

.main {
  background-color: rgb(0, 0, 0);
  height: $height;
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 999;
  .container {
    padding: 0;
    display: flex;
    justify-content: space-between;
  }
  .logo {
    display: flex;
    .logoImg {
      height: $height;
      width: $height;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 37px;
      img {
        height: 56px;
        width: 56px;
      }
    }
    .logoText {
      width: 69px;
    }
    a {
      display: flex;
      align-items: center;
    }
  }
}

.menuSec {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.menu {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}
.active::after {
  content: "";
  position: absolute;
  width: calc(100% + 16px);
  left: -8px;
  height: 12px;
  background-color: #41C7C0;
  z-index: -1;
  bottom: 0;
}
.itemMenu a {
  text-decoration: none;
  position: relative;
  color: #fff;
  z-index: 1;
}

.balanceInformer, .mobileMenu {
  display: flex;
  align-items: center;
}


@media only screen and (max-width: $WIDTH_MOBILE_MENU) {
  .main {
    .menu, .balanceInformer {
      display: none;
    }
  }
}
@media only screen and (min-width: $WIDTH_MOBILE_MENU) {
  .main {
    .mobileMenu {
      display: none;
    }
  }
}
