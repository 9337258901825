$WIDTH_XL: 1200px;
$WIDTH_LG: 992px;
$WIDTH_MD: 768px;
$WIDTH_SM: 576px;
$WIDTH_S8: 360px;
$WIDTH_I5: 320px;
$WIDTH_MOBILE_MENU: 777px;

@font-face {
  font-family: "Rawline";
  font-weight: 400;
  src: url("./Resources/Fonts/rawline-400.ttf") format("truetype");
}

@font-face {
  font-family: "Rawline";
  font-weight: 500;
  src: url("./Resources/Fonts/rawline-500.ttf") format("truetype");
}

@font-face {
  font-family: "Rawline";
  font-weight: 700;
  src: url("./Resources/Fonts/rawline-700.ttf") format("truetype");
}

@font-face {
  font-family: "Rawline";
  font-weight: 900;
  src: url("./Resources/Fonts/rawline-900.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Mono";
  src: url("./Resources/Fonts/robotomono/Robotomono.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("./Resources/Fonts/robotomono/Robotomonobold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("./Resources/Fonts/robotomono/Robotomonoitalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("./Resources/Fonts/robotomono/Robotomonolight.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("./Resources/Fonts/robotomono/Robotomonobolditalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

html body {
  margin: 0;
  background-color: #E6E6E6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
