.boxBtn {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

button.btn {
  border-color: #5c5c5c80;
  color: #5C5C5C;
  &:hover {
    border-color: #5C5C5C;
  }
}

.attentionIcon {
  color: #9E5400;
  display: flex;
  flex-direction: column;
  * {
    margin-top: 0.5rem;
  }
}
 
