.title {
  cursor: pointer;
}
.titleContent {
  text-align: center;
}
.table {
  td {
    border-bottom: none;
    vertical-align: top;
  }
  td.right {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}