.mainForm {
  margin: 37px 37px 0;
}
.boxImage {
  max-width: 343px;
}
.titleBtnOpen {
  cursor: pointer;
}
div.dialogActions {
  justify-content: center;
  margin: 25px 0 37px;
  text-align: center;
}
.uploadInput {
  display: none
}
.title {
  text-align: center;
}
span.note {
  text-align: left;
  display: inline-block;
  line-height: 1.4;
}