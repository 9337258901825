.imgIcon, .titleBtnOpen {
  cursor: pointer;
}

.dialog {
  max-width: 330px;
  &.dialogWithAddress {
    max-width: 450px;
  }
  .content {
    text-align: center;
    margin: 10px 20px;
    color: #000;
    line-height: 1.8rem;
  }
}

div.dialogActions {
  justify-content: space-between;
  margin: 0 5px 5px;
}

.mainForm {
  .inputField {
    width: 100%;
  }
  .inputAddress {
    input {
      text-align: center;
    }
    @media (max-width: 530px) {
      input {
        font-size: .9rem;
      }
    }
    @media (max-width: 480px) {
      input {
        font-size: .8rem;
      }
    }
    @media (max-width: 444px) {
      input {
        font-size: .7rem;
      }
    }
    @media (max-width: 400px) {
      input {
        font-size: .6rem;
      }
    }
  }
}

.formContent {
  margin: 0 15px 30px 15px;
}

.switchBox {
  display: grid;
  grid-template-columns: 100px 50px 100px;
  justify-items: center;
  align-items: baseline;
}

.promo.sell {
  text-align: center;
  & > div > div {
    overflow: hidden;
  }
  .textGradient {
    background: linear-gradient(90deg, #0398e6 5%, #e740b4 95%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .dialogContent {
    width: 500px;
    .imgLeft {
      position: absolute;
      left: 0;
      height: 160px;
      top: calc(50% - 80px);
    }
    .imgRight {
      position: absolute;
      right: -51px;
      bottom: -48px;
      height: 177px;
    }
  }
  .dialogActions {
    justify-content: center;
    button {
      cursor: pointer;
      background-image: linear-gradient(180deg, #3dd3ff 0%, rgba(216, 61, 255, 0.71) 100%);
      border: 0;
      padding: 10px 30px;
      font-size: 14px;
      font-weight: 700;
      color: white;
      border-radius: 4px;
      height: 40px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .promo.sell {
    .dialogContent {
      width: 100%;
      .imgLeft {
        display: none;
      }
      .imgRight {
        display: none;
      }
    }
  }
}