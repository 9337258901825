.indicator {
  display: inline-block;
  height: 0.4rem;
  width: 0.4rem;
  border-radius: 50%;
  margin-left: 5px;
  &.error {
    background-color: red;
  }
  &.success {
    background-color: #05e005;
  }
}
