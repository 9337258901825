.imgIcon, .titleBtnOpen {
  cursor: pointer;
}

.dialog {
  max-width: 515px;
  display: flex;
  column-gap: 1rem;
  & > div {
    z-index: 1;
  }
  img {
    height: 180px;
  }
  .content {
    margin: 10px 0;
    color: #000;
    line-height: 1.8rem;
  }
  b {
    color: #EE6969
  }
  &.dialogRightImg {
    & > div {
      margin-right: 80px;
    }
    img {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  &.dialogRightImgV2 {
    min-width: 320px;
    min-height: 180px;
    & > div {
      margin-right: 80px;
    }
    img {
      height: 130px;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}

button.btnV1 {
  background-color: #497CCE;
  border-radius: 5px;
  &:hover {
    background-color: #497CCE;
  }
}

button.btnV2 {
  background-color: #A559B8;
  border-radius: 5px;
  &:hover {
    background-color: #A559B8;
  }
}

div.dialogActions {
  justify-content: space-between;
  margin: 0 5px 5px;
}

.mainForm {
  .inputField {
    width: 100%;
  }
}

.formContent {
  margin: 0 15px 30px 15px;
}

.switchBox {
  display: grid;
  grid-template-columns: 100px 50px 100px;
  justify-items: center;
  align-items: baseline;
}
